import { Avatar } from 'antd'
import React from 'react'

const defaultMaxStyle = {
  color: '#f56a00',
  backgroundColor: '#fde3cf',
  cursor: 'pointer',
  display: 'flex'
}

export const CustomAvatarGroup = ({
  items = [],
  renderAvatar,
  maxCount = 5,
  maxPopoverTrigger = 'click',
  size = 'normal',
  fontSize = 1.4,
  maxStyle,
  className,
  ...props
}) => {
  return (
    <Avatar.Group
      maxCount={maxCount}
      maxPopoverTrigger={maxPopoverTrigger}
      size={size}
      maxStyle={{ fontSize: `${fontSize - 0.4}em`, ...defaultMaxStyle, ...maxStyle }}
      className={className}
      {...props}
    >
      {items.map(item => renderAvatar(item))}
    </Avatar.Group>
  )
}
