import { sortBy } from 'lodash'
import React from 'react'

import { CustomAvatarGroup } from './avatarGroup'
import { TechnologyAvatar } from './technology'

export const TechnologiesAvatarGroup = ({
  technologies = [],
  size = 'normal',
  fontSize,
  max = 5,
  maxPopoverTrigger = 'click',
  className,
  ...props
}) => {
  const renderAvatar = technology => (
    <TechnologyAvatar technology={technology} key={technology.name + technology.id} />
  )
  const sortedTechnologies = sortBy(technologies, item => item?.attributes?.logo?.data)

  return (
    <CustomAvatarGroup
      items={sortedTechnologies}
      renderAvatar={renderAvatar}
      maxCount={max}
      maxPopoverTrigger={maxPopoverTrigger}
      size={size}
      fontSize={fontSize}
      className={className}
      {...props}
    />
  )
}
