export const getAttributesNames = array => {
  return array && array.data && array.data.length > 0
    ? array.data.map(element => {
        return ` ${element.attributes.name}`
      })
    : ''
}

export const hash = text =>
  text
    .split('')
    .map(c => c.charCodeAt(0))
    .reduce((a, b) => a + b)
