import { Avatar, Tooltip } from 'antd'
import classNames from 'classnames'
import { compact, first, join, map, slice, split, upperCase } from 'lodash'
import Image from 'next/image'
import React from 'react'
import { getStrapiMedia } from 'utils/media'

const getInitialsFromText = text =>
  join(
    map(slice(compact(split(text, ' ')), 0, 2), word => upperCase(first(word))),
    ''
  )

export const CustomAvatar = ({
  name,
  acronym,
  tooltip,
  src,
  color,
  fontSize = 1.4,
  size = 'default',
  shape = 'circle',
  className,
  style,
  ...props
}) => {
  const icon = <>{acronym ?? getInitialsFromText(name)}</>
  const adaptativeFontSize = `${fontSize - 0.2 * icon.length}em`
  const cn = classNames('avatar', className)

  const image = src && (
    <Image className={cn} src={getStrapiMedia(src)} alt={name} layout="fill" />
  )

  return (
    <Tooltip title={tooltip} placement="top">
      <Avatar
        src={image}
        size={size}
        shape={shape}
        icon={icon}
        style={{ ...style, backgroundColor: color, fontSize: adaptativeFontSize }}
        className={cn}
        draggable={false}
        {...props}
      />
    </Tooltip>
  )
}
