import classnames from 'classnames'
import { memo, useCallback, useState } from 'react'

const FlipCard = ({ children, className }) => {
  const [flip, setFlip] = useState(false)

  const cn = classnames('flip-card', { ['back-flip']: flip })
  const cnInner = classnames('flip-card-inner', className)

  const handleClick = useCallback(() => {
    setFlip(!flip)
  }, [flip])

  const handleMouseLeave = useCallback(() => {
    setFlip(false)
  }, [flip])

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter' || event.key === ' ') {
        setFlip(!flip)
      }
    },
    [flip]
  )

  return (
    <div className={cn} onMouseLeave={handleMouseLeave}>
      <div className={cnInner}>
        <div
          className="flip-card-front"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          role="button"
        >
          {children[0]}
        </div>
        <div
          className="flip-card-back"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          role="button"
        >
          {children[1]}
        </div>
      </div>
    </div>
  )
}

export default memo(FlipCard)
