export const randomLightColors = [
  '#EFADFF',
  '#FFDBB0',
  '#FFCACA',
  '#CAC2FF',
  '#FFDBE6',
  '#B7EEFF'
]

export const chartColors = ['#FFDA0A', '#4AD548', '#CA5BEC', '#183e9e']
