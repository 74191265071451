import React from 'react'
import { generateRandomLightColor } from 'utils/randomColor'

import { tertiaryBackgroundColor } from '../../../stylesheet/abstracts/variables.module.scss'
import { CustomAvatar } from './avatar'

export const TechnologyAvatar = ({
  technology: {
    id,
    attributes: { acronym, name, color, logo }
  },
  ...props
}) => {
  return (
    <CustomAvatar
      name={name}
      tooltip={name}
      acronym={acronym}
      color={color ?? generateRandomLightColor(id)}
      src={logo?.data?.attributes?.url}
      style={{
        borderColor: tertiaryBackgroundColor
      }}
      {...props}
      className={logo?.data ? 'avatar-padding' : ''}
    />
  )
}
