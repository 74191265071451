import { memo } from 'react'

import CategoryCard from '@/components/elements/cards/categoryCard'
import HeaderOverhaul from '@/components/elements/headerOverhaul'

const WhatWeDo = ({ data }) => {
  const { header, categories } = data

  return (
    <div className="what-we-do-wrapper">
      <HeaderOverhaul header={header} subtitle />
      <div className="what-we-do-container grid-wrapper">
        {categories.data.map(
          ({ id, attributes: { name, subtitle, description, logo, technologies } }) => (
            <CategoryCard
              key={id}
              id={id}
              name={name}
              subtitle={subtitle}
              description={description}
              logo={logo}
              technologies={technologies}
            />
          )
        )}
      </div>
    </div>
  )
}

export default memo(WhatWeDo)
