import { Divider } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { memo } from 'react'

import CustomImage from '@/components/elements/images/image'

import { TechnologiesAvatarGroup } from '../images/technologiesAvatarGroup'
import FlipCard from './components/flipCard'
import { generateRandomLightColor } from 'utils/randomColor'

const CategoryCard = ({ logo, name, subtitle, description, technologies, id }) => (
  <div className="category-card">
    <FlipCard className="category-flipcard">
      <div className="category-card-content">
        <div className="category-card-header">
          {logo.data && (
            <div
              className="category-custom-image-container"
              style={{
                backgroundColor: generateRandomLightColor(id)
              }}
            >
              <CustomImage
                media={logo.data.attributes}
                width="70px"
                height="70px"
                shimmer
              />
            </div>
          )}

          <div className="category-item-header">
            {!isEmpty(name) && <div className="category-item-title">{name}</div>}
            {!isEmpty(subtitle) && (
              <div className="category-item-subtitle">{subtitle}</div>
            )}
          </div>
        </div>

        {!isEmpty(technologies) && (
          <div className="category-technologies-space">
            <span className="category-technologies-divider">
              <Divider />
            </span>
            <TechnologiesAvatarGroup
              technologies={technologies.data}
              maxPopoverTrigger="hover"
              className="category-technologies"
            />
          </div>
        )}
      </div>
      <div className="category-card-content">
        <div className="category-item-description">{description}</div>
      </div>
    </FlipCard>
  </div>
)

export default memo(CategoryCard)
